export interface Unit {
  unit_code: string;
  unit_name: string;
}

export class UnitModel {
  constructor(private props: Unit) {}

  static Builder(): UnitBuilder {
    return new UnitBuilder();
  }

  getValues() {
    return this.props;
  }
}

class UnitBuilder implements Unit {
  unit_code: string;
  unit_name: string;

  build(): Unit {
    return new UnitModel({ unit_code: this.unit_code, unit_name: this.unit_name }).getValues();
  }

  withCode(code: string): UnitBuilder {
    this.unit_code = code;
    return this;
  }

  withName(name: string) {
    this.unit_name = name;
    return this;
  }
}

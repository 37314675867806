import { Component, input, InputSignal, output, OutputEmitterRef } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [NgClass],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css',
})
export class ModalComponent {
  title: InputSignal<string> = input.required();
  content: InputSignal<string> = input('');
  cancelButton: InputSignal<boolean> = input(false);
  confirmButton: InputSignal<boolean> = input(false);
  closeButton: InputSignal<boolean> = input(false);
  disableButtons: InputSignal<boolean> = input(false);
  dangerousAction: InputSignal<boolean> = input(false);
  confirm: OutputEmitterRef<boolean> = output();
  close: OutputEmitterRef<undefined> = output();

  onConfirm() {
    this.confirm.emit(true);
    this.close.emit(void 0);
  }

  onCancel() {
    this.confirm.emit(false);
    this.close.emit(void 0);
  }

  onClose() {
    this.close.emit(void 0);
  }
}

<aside>
  <div class="flex justify-end gap-3.5 py-2">
    @if (canEditSubset()) {
      <button
        class="w-[98px] h-[46px] px-5 py-4 bg-white rounded-xl border border-blue-950 justify-center items-center inline-flex"
        (click)="goToSubsetEdit()"
      >
        <div class="text-blue-950 text-sm font-semibold font-['Work Sans']">Edit</div>
        <div class="w-4 h-4 relative ml-3">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="black"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="black"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M9.65661 17L6.99975 17L6.99975 14M6.10235 14.8974L17.4107 3.58902C18.1918 2.80797 19.4581 2.80797 20.2392 3.58902C21.0202 4.37007 21.0202 5.6364 20.2392 6.41745L8.764 17.8926C8.22794 18.4287 7.95992 18.6967 7.6632 18.9271C7.39965 19.1318 7.11947 19.3142 6.8256 19.4723C6.49475 19.6503 6.14115 19.7868 5.43395 20.0599L3 20.9998L3.78312 18.6501C4.05039 17.8483 4.18403 17.4473 4.3699 17.0729C4.53497 16.7404 4.73054 16.424 4.95409 16.1276C5.20582 15.7939 5.50466 15.4951 6.10235 14.8974Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
          </svg>
        </div>
      </button>
    }

    <button
      class="w-[246px] h-[46px] px-5 py-4 bg-blue-600 rounded-xl justify-center items-center inline-flex"
      (click)="launchCalculation()"
    >
      <div class="text-white text-sm font-semibold font-['Work Sans']">Launch a new calculation</div>
      <div class="w-4 h-4 relative ml-3">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="white"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="white"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M20 4L4 20" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            <path
              d="M4 7H7M10 7H7M7 7V4M7 7V10"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            ></path>
            <path
              d="M14 17H17H20"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            ></path>
          </g>
        </svg>
      </div>
    </button>

    <button
      class="w-[197px] h-[46px] px-5 py-4 mr-[5px] bg-blue-950 rounded-xl justify-center items-center inline-flex"
      (click)="publishResult()"
    >
      <div class="text-white text-sm font-semibold font-['Work Sans']">Publish the result</div>
      <div class="w-5 h-5 relative ml-3">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="white"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="white"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      </div>
    </button>
  </div>
</aside>

@if (showCalculationPopup()) {
  <app-modal
    (close)="closeCalculationPopup()"
    [title]="'Launch calculation'"
    [content]="calculationContent()"
    [closeButton]="isPopupAbleToClose()"
  ></app-modal>
}

@if (showPublishResultPopup()) {
  <app-modal
    (close)="closePublishResultPopup()"
    [title]="'Publishing result'"
    [content]="publishContent()"
    [closeButton]="isPopupAbleToClose()"
  ></app-modal>
}

<div class="grid grid-cols-3">
  <!-- Overview -->
  <section class="h-60 pl-10 pb-[40px] mb-[5px] ml-[5px] bg-slate-200 rounded-xl col-span-2">
    <div
      class="float-right relative bg-blue-600 rounded-tr-xl rounded-bl-xl justify-center items-center gap-2.5 flex w-20 h-8"
    >
      <div class="text-white text-base font-['Work Sans']">Id : {{ subsetDisplay()?.userSubsetId }}</div>
    </div>
    <div class="pt-[40px] mt-[5px] flex-col justify-start items-start gap-5 inline-flex">
      <div class="justify-start items-center gap-5 inline-flex">
        <div class="text-blue-600 text-3xl font-semibold font-['Work Sans']">{{ subsetDisplay()?.name }}</div>
        <div class="justify-start items-center gap-5 flex">
          @if (subsetDisplay()?.status) {
            <div>
              <app-subset-status-text [status]="subsetDisplay()?.status"></app-subset-status-text>
            </div>
          }
          @if (subsetDisplay()?.publicationDate) {
            <div
              class="w-[225px] px-5 py-2.5 bg-slate-300 rounded-[20px] border border-emerald-600 justify-center items-center gap-2.5 flex"
            >
              <div class="text-center text-emerald-600 text-base font-medium font-['Work Sans']">
                Published on {{ subsetDisplay()?.publicationDate | date: 'dd/MM/YYYY' }}
              </div>
            </div>
          }
        </div>
      </div>
      <div class="justify-start items-start gap-2.5 inline-flex">
        <div class="px-5 py-2.5 rounded-xl border border-blue-600 justify-center items-center gap-2.5 flex">
          <span class="text-blue-600 text-base font-normal font-['Work Sans']"
            ><span class="font-bold">Year </span>{{ subsetDisplay()?.fiscalYear }}</span
          >
        </div>
        <div class="px-5 py-2.5 rounded-xl border border-blue-600 justify-center items-center gap-2.5 flex">
          <span class="text-blue-600 text-base font-normal font-['Work Sans']"
            ><span class="font-bold">Responsible </span
            >{{ getResponsibleName(subsetDisplay()?.responsible?.uuid || '') }}</span
          >
        </div>
      </div>
    </div>
    <div class="pt-[40px] mt-[5px] flex-col justify-start items-end gap-4 inline-flex float-right">
      @if (subsetDisplay()?.statusDate) {
        <div class="text-right">
          <span class="text-blue-600 text-base font-semibold font-['Work Sans']"
            >Status date:&nbsp;{{ subsetDisplay()?.statusDate | date: 'dd/MM/YYYY' }}</span
          >
        </div>
      }
      @if (subsetDisplay()?.updateDate) {
        <div class="text-right">
          <span class="text-blue-600 text-base font-semibold font-['Work Sans']"
            >Update date:&nbsp;{{ subsetDisplay()?.updateDate | date: 'dd/MM/YYYY' }}</span
          >
        </div>
      }
      @if (subsetDisplay()?.updateBy) {
        <div class="text-right">
          <span class="text-blue-600 text-base font-semibold font-['Work Sans']"
            >Updated by:&nbsp;{{ getResponsibleName(subsetDisplay()?.updateBy?.uuid || '') }}</span
          >
        </div>
      }
      <div class="text-right">
        <span class="text-blue-600 text-base font-semibold font-['Work Sans']"
          >Creation date:&nbsp;{{ subsetDisplay()?.creationDate | date: 'dd/MM/YYYY' }}</span
        >
      </div>
    </div>
    @if (subsetDisplay()?.comment) {
      <div class="h-10 w-1/3 px-3 py-2 my-2 bg-white rounded-[100px] justify-start items-center gap-2 flex">
        <div class="w-5 h-5 relative">
          <svg fill="#000000" viewBox="0 0 200 200" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <title></title>
              <path
                d="M100,18.54C45,18.54,10,52,10,93s35,74.5,90,74.5c9.5,0,22.5-1.5,30-4L159,180c6.5,4,15-1,15-8.5v-36c.5-.5.5-1,1-1,10-15,15-22.5,15-41.5C190,52,155,18.54,100,18.54Zm67.5,89a168.37,168.37,0,0,1-10,17c-2,3.5-3.5,7-3.5,11v19L140,146a18.22,18.22,0,0,0-16-1.5c-4.5,1.5-15,2.5-23.5,2.5-47,0-70-27-70-54.5-.5-27,22.5-54,69.5-54s70,27,70,54.5c0,7.5-1,11-2.5,14.5ZM85,93.54a15,15,0,1,0,15-15A15,15,0,0,0,85,93.54Zm-40,0a15,15,0,1,0,30,0h0a15,15,0,0,0-30,0Zm80,0a15,15,0,0,0,30,0v0a15,15,0,1,0-30,0Z"
              ></path>
            </g>
          </svg>
        </div>
        <div
          class="w-11/12 text-black text-base font-normal font-['Work Sans'] overflow-hidden overflow-ellipsis whitespace-nowrap"
          [title]="subsetDisplay()?.comment"
        >
          {{ subsetDisplay()?.comment }}
        </div>
      </div>
    }
  </section>

  <!-- Scopes -->
  <section
    class="max-h-[740px] overflow-y-auto my-[5px] mx-[5px] p-10 bg-gray-300 rounded-xl flex-col col-span-1 row-span-3"
  >
    <div class="self-stretch pb-2 text-black text-2xl font-semibold font-['Work Sans']">Scope:</div>
    <div class="self-stretch justify-start items-start gap-5 inline-flex flex-wrap">
      @for (scope of scopesList(); track $index) {
        <div class="p-3 bg-blue-950 rounded-[100px] justify-center items-center gap-2.5 flex">
          <div class="px-3 py-1 bg-slate-500 rounded-[20px] justify-center items-center gap-2.5 flex">
            <div class="text-white text-sm font-normal font-['Work Sans']">{{ scope.plantCode }}</div>
          </div>
          <div class="text-white text-sm font-normal font-['Work Sans']">{{ scope.name }}</div>
        </div>
      }
    </div>
  </section>

  <!-- Calculation -->
  <section class="h-60 px-10 py-[60px] my-[5px] mx-[5px] bg-published-color rounded-xl col-span-2">
    <div class="self-stretch flex-col justify-start items-start gap-5 inline-flex">
      <div class="justify-start items-center gap-5 inline-flex">
        <div class="text-emerald-600 text-3xl font-semibold font-['Work Sans']">Last calculation</div>
      </div>
      @if (subsetDisplay()?.lastCalculationDate) {
        <div class="justify-start items-start gap-2.5 inline-flex">
          @if (!isNaN(subsetDisplay()?.lastCalculationIteration || NaN)) {
            <div class="px-5 py-2.5 rounded-xl border border-emerald-600 justify-center items-center gap-2.5 flex">
              <span class="text-emerald-600 text-base font-semibold font-['Work Sans']"
                >Iteration&nbsp;#{{ subsetDisplay()?.lastCalculationIteration }}</span
              >
            </div>
          }
          <div class="px-5 py-2.5 rounded-xl border border-emerald-600 justify-center items-center gap-2.5 flex">
            <span class="text-emerald-600 text-base font-semibold font-['Work Sans']">Calculation date</span>
            <span class="text-emerald-600 text-base font-normal font-['Work Sans']">{{
              subsetDisplay()?.lastCalculationDate | date: 'dd/MM/YYYY'
            }}</span>
            <span class="text-emerald-600 text-base font-semibold font-['Work Sans']">Launched by</span>
            <span class="text-emerald-600 text-base font-normal font-['Work Sans']">{{
              getResponsibleName(subsetDisplay()?.lastCalculationBy?.uuid || '')
            }}</span>
          </div>
        </div>
      }
    </div>
    <button
      class="w-[259px] self-stretch px-5 py-4 bg-emerald-600 rounded-xl justify-center items-center gap-3.5 inline-flex float-right"
      (click)="retrieveCalculationReport()"
    >
      <div class="text-white text-sm font-semibold font-['Work Sans']">See the calculation results</div>
      <div class="w-5 h-5 relative">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0" stroke="white"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="white"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      </div>
    </button>
  </section>

  <!-- Publication -->
  <section class="h-60 px-10 py-[60px] my-[5px] mx-[5px] bg-slate-300 rounded-xl col-span-2">
    <div class="self-stretch flex-col justify-start items-start gap-5 inline-flex">
      <div class="justify-start items-center gap-5 inline-flex">
        <div class="text-slate-600 text-3xl font-semibold font-['Work Sans']">Last publication</div>
      </div>
      @if (subsetDisplay()?.publicationDate) {
        <div class="justify-start items-start gap-2.5 inline-flex">
          @if (!isNaN(subsetDisplay()?.publishedCalculationIteration || NaN)) {
            <div class="px-5 py-2.5 rounded-xl border border-slate-600 justify-center items-center gap-2.5 flex">
              <span class="text-slate-600 text-base font-semibold font-['Work Sans']"
                >Iteration&nbsp;#{{ subsetDisplay()?.publishedCalculationIteration }}</span
              >
            </div>
          }
          <div class="px-5 py-2.5 rounded-xl border border-slate-600 justify-center items-center gap-2.5 flex">
            <span class="text-slate-600 text-base font-semibold font-['Work Sans']">Publication date</span>
            <span class="text-slate-600 text-base font-normal font-['Work Sans']">{{
              subsetDisplay()?.publicationDate | date: 'dd/MM/YYYY'
            }}</span>
            <span class="text-slate-600 text-base font-semibold font-['Work Sans']">Launched by</span>
            <span class="text-slate-600 text-base font-normal font-['Work Sans']">{{
              getResponsibleName(subsetDisplay()?.publicationBy?.uuid || '')
            }}</span>
          </div>
        </div>
      }
    </div>
  </section>
</div>

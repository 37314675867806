@if (showModalDeleteSubset()) {
  <app-modal
    (confirm)="onDeletionSubset($event, subsetToDelete()?.uuid)"
    (close)="onClosingDeletionSubset()"
    [title]="'Danger Zone'"
    [content]="'You are about to delete this subset, do you want to continue?'"
    [cancelButton]="true"
    [confirmButton]="true"
    [disableButtons]="isDeletingSubset()"
    [dangerousAction]="true"
  ></app-modal>
}

<!-- Search -->
<form class="flex gap-6 items-center py-2" [formGroup]="filterForm">
  <span class="relative">
    <input type="text" class="w-80" placeholder="Search for a subset name, Id..." [formControl]="searchFilter" />
    <svg xmlns="http://www.w3.org/2000/svg" class="absolute right-3 top-5 z-20 w-5 h-5" viewBox="0 0 24 24">
      <path d="M22 20L20 22 14 16 14 14 16 14z"></path>
      <path
        d="M9,16c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7C16,12.9,12.9,16,9,16z M9,4C6.2,4,4,6.2,4,9c0,2.8,2.2,5,5,5 c2.8,0,5-2.2,5-5C14,6.2,11.8,4,9,4z"
      ></path>
      <path d="M13.7 12.5H14.7V16H13.7z" transform="rotate(-44.992 14.25 14.25)"></path>
    </svg>
  </span>

  <select class="w-32" [formControl]="fiscalYearFilter">
    @for (year of this.computeFiscalYearOptions(); track year) {
      <option>{{ year }}</option>
    }
  </select>

  <button class="underline cursor-pointer" (click)="resetForm()">clear</button>

  <a
    class="flex items-center bg-slate-600 ml-auto text-white py-2 px-4 rounded-lg hover:bg-slate-500"
    routerLink="/new-subset"
  >
    Create a new subset
    <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
    </svg>
  </a>
</form>

<!-- Results -->
<table class="w-full mt-8 border-collapse table-fixed">
  <thead class="bg-emerald-600 text-white h-12">
    <tr>
      <th class="w-16 xl:w-24">id</th>
      <th class="w-20 xl:w-32">Year</th>
      <th>Subset Name</th>
      <th>Scope</th>
      <th class="relative">
        Responsible
        <button (click)="displayResponsibleFilter()" class="relative ml-2 xl:ml-3">
          <img src="../../../assets/svgs/filter.svg" alt="F" height="16" width="16" />
          <span
            [ngClass]="responsibleFilterNumberSelected !== undefined ? '' : 'hidden'"
            class="absolute -top-[0.5rem] -right-[0.5rem] bg-red-600 text-white rounded-full w-4 h-4 text-sm flex items-center justify-center"
            >{{ responsibleFilterNumberSelected }}</span
          >
        </button>

        <!-- popup filter responsible FIXME: why both ifs ? -->
        <form [formGroup]="filterForm" *ngIf="isResponsibleFilterPopupDisplayed()">
          <app-popover [show]="isResponsibleFilterPopupDisplayed()">
            <div class="grid grid-cols-[auto_1fr] min-w-56" formGroupName="responsible">
              @for (responsible of responsibleFilterControls; track i; let i = $index) {
                <input
                  type="checkbox"
                  [id]="'responsible_checkbox' + i"
                  class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                  [formControlName]="responsible[0]"
                  [value]="responsible[1].value"
                />
                <label [for]="'responsible_checkbox' + i" class="text-left">{{ getResponsibleName(i) }}</label>
              }
            </div>
          </app-popover>
        </form>
      </th>
      <th class="relative w-40 xl:w-52">
        Status
        <button (click)="displayStatusFilter()" class="relative ml-2 xl:ml-3">
          <img src="../../../assets/svgs/filter.svg" alt="F" height="16" width="16" />
          <span
            [ngClass]="statusFilterNumberSelected !== undefined ? '' : 'hidden'"
            class="absolute -top-[0.5rem] -right-[0.5rem] bg-red-600 text-white rounded-full w-4 h-4 text-sm flex items-center justify-center"
            >{{ statusFilterNumberSelected }}</span
          >
        </button>

        <!-- popup filter status FIXME: why both ifs ?-->
        <form [formGroup]="filterForm" *ngIf="isStatusFilterPopupDisplayed()">
          <app-popover [show]="isStatusFilterPopupDisplayed()">
            <div class="grid grid-cols-[auto_1fr] min-w-20" formGroupName="status">
              @for (status of statusFilterControls; track i; let i = $index) {
                <input
                  type="checkbox"
                  [id]="'status_checkbox' + i"
                  class="w-[20px] h-[20px] accent-emerald-600 mr-4"
                  [formControlName]="status[0]"
                  [value]="status[1].value"
                />
                <label [for]="'status_checkbox' + i" class="text-left">{{ status[0] }}</label>
              }
            </div>
          </app-popover>
        </form>
      </th>
      <th class="w-24 xl:w-32">Status date</th>
      <th class="w-12 xl:w-16"></th>
    </tr>
  </thead>
  <tbody>
    @for (subset of subsetsDisplay(); track subset.uuid) {
      <tr
        class="even:bg-slate-50 hover:bg-emerald-50 text-blue-950 font-medium cursor-pointer [&>td]:py-3 [&>td]:pl-2 [&>td]:text-center"
        (click)="goToSubset(subset.uuid)"
      >
        <td>
          {{ subset.userSubsetId }}
        </td>
        <td>
          {{ subset.fiscalYear }}
        </td>
        <td class="!text-left">
          {{ subset.name }}
        </td>
        <td
          class="relative min-w-[200px] !text-left"
          (mouseleave)="onMouseLeave()"
          (mouseenter)="onMouseEnter(subset.uuid)"
        >
          {{ subset.scopesShort }}
          <app-popover [show]="scopeHovered(subset.uuid)">
            @for (scope of subset.scopes; track i; let i = $index) {
              <div class="grid grid-cols-[auto_1fr] gap-4 [&>div]:text-left [&>div]:my-1">
                <div>
                  <div class="text-slate-50 bg-slate-700 rounded-lg px-3 py-0.5 w-20 text-center">
                    {{ scope.plantCode }}
                  </div>
                </div>
                <div class="lowercase">{{ scope.name }}</div>
              </div>
            }
          </app-popover>
        </td>
        <td class="!text-left">
          {{ subset.responsible.getName() }}
        </td>
        <td>
          <app-subset-status-text [status]="subset.status"></app-subset-status-text>
        </td>
        <td>
          {{ subset.statusDate | date: 'dd/MM/YYYY' }}
        </td>
        <td [ngClass]="canUserDeleteSubset() && canSubsetBeDeleted(subset) ? 'cursor-default' : 'cursor-pointer'">
          @if (canUserDeleteSubset() && canSubsetBeDeleted(subset)) {
            <div class="flex justify-center items-center">
              <button
                class="w-6 h-6 bg-red-600 rounded-[20px] flex items justify-center"
                (click)="startDeleteSubset($event, subset.uuid)"
              >
                <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                  <line x1="1" y1="1" x2="9" y2="9" stroke="white" stroke-width="2" />
                  <line x1="1" y1="9" x2="9" y2="1" stroke="white" stroke-width="2" />
                </svg>
              </button>
            </div>
          }
        </td>
      </tr>
    }
  </tbody>
</table>

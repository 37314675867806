import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage, SlicePipe } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { ChangePageEvent } from './utils/cactus-events/changepage';
import { PopoverComponent } from './utils/components/popover/popover.component';
import { Observable } from 'rxjs';
import { CactusVersionService } from './services/cactus-version/cactus-version.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AmplifyAuthenticatorModule,
    NgIf,
    RouterLink,
    RouterLinkActive,
    NgClass,
    NgOptimizedImage,
    PopoverComponent,
    AsyncPipe,
    SlicePipe,
    NgForOf,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private cactusVersionService = inject(CactusVersionService);
  private changePageEvent: ChangePageEvent = inject(ChangePageEvent);

  cactusVersions: Observable<string[]> = this.cactusVersionService.retrieveVersions();

  showVersion = signal(false);
  isLoginChecked = signal(false);
  isLogoLoaded = signal(false);
  isLoaded = computed(() => this.isLoginChecked() && this.isLogoLoaded());
  public ngOnInit(): void {
    this.authService.initialize();
    this.authService.login().finally(() => {
      this.isLoginChecked.set(this.authService.isLoginChecked());
    });
  }

  dispatchChangePageEvent(page: string) {
    this.changePageEvent.nextRoute = page;
    window.dispatchEvent(this.changePageEvent.generateEvent());
  }

  public ngOnDestroy(): void {
    this.authService.destroy();
  }

  onLogoLoad() {
    this.isLogoLoaded.set(true);
  }

  isProfileAdvanced() {
    return this.authService.getProfile() === 'advanced';
  }

  getShortName() {
    const { firstName, lastName } = this.authService.getName();
    return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
  }

  getName() {
    const { firstName, lastName } = this.authService.getName();
    return `${firstName} ${lastName.toUpperCase()}`;
  }
}

import { Subset, SubsetModel, SubsetStatus } from '../../app/services/subset/model/subset.model';
import { scopeListSeed } from './scopeList.seed';
import { responsibleListSeed } from './responsibleList.seed';
import { DateProvider } from '../../app/services/year-of-consumption/model/date-provider';

export const subsetListSeed: Subset[] = [
  SubsetModel.builder()
    .withName('Lorem Ipsum dolor sit amet')
    .withUuid('38137670-4a15-46d6-91e3-9fec35b8b6b0')
    .withResponsible(responsibleListSeed[0])
    .withYear('2023')
    .withScope(scopeListSeed[0])
    .withCreationDate(DateProvider.now())
    .withUpdateDate(DateProvider.now())
    .withUpdateBy(responsibleListSeed[0])
    .withStatus(SubsetStatus.CREATED)
    .withStatusDate(DateProvider.now())
    .build(),
  SubsetModel.builder()
    .withName('Vestibulum eu eleifend felis')
    .withUuid('5180f36d-5ba0-4dfc-aed3-367a30e3d256')
    .withResponsible(responsibleListSeed[1])
    .withYear('2023')
    .withScope(scopeListSeed[0])
    .withScope(scopeListSeed[1])
    .withLastCalculationIteration(3)
    .withLastCalculationDate(DateProvider.now())
    .withLastCalculationBy(responsibleListSeed[0])
    .withCreationDate(DateProvider.now())
    .withUpdateDate(DateProvider.now())
    .withUpdateBy(responsibleListSeed[0])
    .withComment('Ut enim ad minima veniam')
    .withStatus(SubsetStatus.IN_PROGRESS)
    .withStatusDate(DateProvider.now())
    .build(),
  SubsetModel.builder()
    .withName('Fusce et molestie metus')
    .withUuid('3ed27522-f79a-474d-b628-9a1f77215beb')
    .withResponsible(responsibleListSeed[2])
    .withYear('2023')
    .withScope(scopeListSeed[0])
    .withScope(scopeListSeed[1])
    .withScope(scopeListSeed[2])
    .withLastCalculationIteration(4)
    .withLastCalculationDate(DateProvider.now())
    .withLastCalculationBy(responsibleListSeed[2])
    .withPublicationIteration(4)
    .withPublicationDate(DateProvider.now())
    .withPublicationBy(responsibleListSeed[2])
    .withCreationDate(DateProvider.now())
    .withUpdateDate(DateProvider.now())
    .withStatusDate(DateProvider.now())
    .withUpdateBy(responsibleListSeed[2])
    .withComment(
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    )
    .withStatus(SubsetStatus.PUBLISHED)
    .build(),
  SubsetModel.builder()
    .withName('Nullam fringilla dolor rhoncus elit')
    .withUuid('24c13077-a81b-4ecf-bbf8-8a6e0d50b814')
    .withResponsible(responsibleListSeed[3])
    .withYear('2023')
    .withScope(scopeListSeed[0])
    .withScope(scopeListSeed[1])
    .withScope(scopeListSeed[2])
    .withScope(scopeListSeed[3])
    .withScope(scopeListSeed[4])
    .withLastCalculationIteration(5)
    .withLastCalculationDate(DateProvider.now())
    .withLastCalculationBy(responsibleListSeed[2])
    .withCreationDate(DateProvider.now())
    .withUpdateDate(DateProvider.now())
    .withUpdateBy(responsibleListSeed[2])
    .withStatus(SubsetStatus.REWORK)
    .withStatusDate(DateProvider.now())
    .build(),
  SubsetModel.builder()
    .withName('Quis nostrud exercitation ullamco laboris')
    .withUuid('quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat')
    .withResponsible(responsibleListSeed[4])
    .withYear('2023')
    .withScope(scopeListSeed[0])
    .withScope(scopeListSeed[1])
    .withScope(scopeListSeed[2])
    .withScope(scopeListSeed[3])
    .withScope(scopeListSeed[4])
    .withScope(scopeListSeed[5])
    .withScope(scopeListSeed[6])
    .withScope(scopeListSeed[7])
    .withScope(scopeListSeed[8])
    .withScope(scopeListSeed[9])
    .withScope(scopeListSeed[10])
    .withLastCalculationIteration(2)
    .withLastCalculationDate(DateProvider.now())
    .withLastCalculationBy(responsibleListSeed[4])
    .withCreationDate(DateProvider.now())
    .withUpdateDate(DateProvider.now())
    .withUpdateBy(responsibleListSeed[4])
    .withStatus(SubsetStatus.REWORK)
    .withStatusDate(DateProvider.now())
    .build(),
];

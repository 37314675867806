@if (showModalChangePage()) {
  <app-modal
    (confirm)="onConfirmChangePage($event)"
    [title]="'Unsaved Changes'"
    [content]="'You have unsaved changes, do you want to discard them and continue?'"
    [cancelButton]="true"
    [confirmButton]="true"
  ></app-modal>
}
@if (showModalChangeDisplay()) {
  <app-modal
    (confirm)="onConfirmChangeDisplay($event)"
    [title]="'Unsaved Changes'"
    [content]="'You have unsaved changes, do you want to discard them and continue?'"
    [cancelButton]="true"
    [confirmButton]="true"
  ></app-modal>
}

<div class="w-full pt-4 bg-white flex-col justify-end items-start gap-10 inline-flex">
  <div class="w-full">
    <div class="w-full grid grid-cols-3">
      <h1 class="text-[#28285f] text-3xl font-bold font-['Work Sans'] px-3 col-span-2">EFA Management</h1>
      <div class="text-[#28285f] text-base font-normal font-['Work Sans'] px-3 col-span-1 text-right">
        Last save : {{ lastSaveDate | date: 'dd/MM/YYYY HH:mm:ss' }}
      </div>
    </div>
    <div class="justify-start items-center gap-5 inline-flex">
      <span class="relative">
        <input
          type="text"
          class="text-[#a79c9c] text-sm w-96 h-12"
          placeholder="Search for a subset name, Id..."
          (keydown)="onKeydown($event)"
          [formControl]="searchForm" />
        <button class="absolute top-5 right-3" (click)="onKeydown('click')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            class="w-5 h-5 text-gray-500 hover:text-gray-700"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M22 20L20 22 14 16 14 14 16 14z"></path>
            <path
              d="M9,16c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7C16,12.9,12.9,16,9,16z M9,4C6.2,4,4,6.2,4,9c0,2.8,2.2,5,5,5 c2.8,0,5-2.2,5-5C14,6.2,11.8,4,9,4z"
            ></path>
            <path d="M13.7 12.5H14.7V16H13.7z" transform="rotate(-44.992 14.25 14.25)"></path>
          </svg></button
      ></span>

      <app-plant-code-list
        [rePushScope]="rePushScope"
        (outputScopeSelectedList)="$event && addScopeSelected($event)"
      ></app-plant-code-list>

      @for (scope of scopesSelected(); track $index) {
        <app-plant-code [scope]="scope" (outputAction)="removeScopeSelected($event)"></app-plant-code>
      }

      @if (sourceErpCodes().length) {
        <div [formGroup]="filterExclusiveSourceERPForm()">
          <div class="justify-center items-center gap-3.5 flex">
            @for (erp of exclusiveErp.controls; track i; let i = $index) {
              <button
                class="px-5 py-4 rounded-xl border border-[#28285f]"
                [ngClass]="getErpButtonSelected(i) ? 'erp-button__active' : 'erp-button__unselected'"
                formArrayName="exclusiveERP"
                (click)="onSourceErpFilterExclusiveChange(i)"
              >
                <span class="text-sm font-semibold font-['Work Sans']">{{ getErpCode(i) }}</span>
              </button>
            }
          </div>
        </div>
      }
    </div>

    @if (canApplyChange()) {
      <button
        class="justify-end px-5 py-4 bg-[#28285f] rounded-xl border border-white items-center gap-3.5 float-right mr-2"
        [ngClass]="isApplyingChanges() ? 'bg-gray-600' : 'bg-[#28285f]'"
        [disabled]="isApplyingChanges()"
        (click)="applyChange()"
      >
        <span class="text-white text-sm font-semibold font-['Work Sans']">Save</span>
      </button>
    }
  </div>
</div>

<table class="w-full h-9 mt-5">
  <thead class="border-b border-zinc-500">
    <tr class="bg-[#f6fafd] rounded-tl-xl rounded-tr-xl border border-[#b1c1ca]">
      <th class="text-left">
        <button (click)="expandAll()">
          <svg width="1rem" height="1rem" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <title>plus-square</title>
              <desc>Created with Sketch Beta.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-Set" transform="translate(-100.000000, -1035.000000)" fill="#707070">
                  <path
                    d="M130,1063 C130,1064.1 129.104,1065 128,1065 L104,1065 C102.896,1065 102,1064.1 102,1063 L102,1039 C102,1037.9 102.896,1037 104,1037 L128,1037 C129.104,1037 130,1037.9 130,1039 L130,1063 L130,1063 Z M128,1035 L104,1035 C101.791,1035 100,1036.79 100,1039 L100,1063 C100,1065.21 101.791,1067 104,1067 L128,1067 C130.209,1067 132,1065.21 132,1063 L132,1039 C132,1036.79 130.209,1035 128,1035 L128,1035 Z M122,1050 L117,1050 L117,1045 C117,1044.45 116.552,1044 116,1044 C115.448,1044 115,1044.45 115,1045 L115,1050 L110,1050 C109.448,1050 109,1050.45 109,1051 C109,1051.55 109.448,1052 110,1052 L115,1052 L115,1057 C115,1057.55 115.448,1058 116,1058 C116.552,1058 117,1057.55 117,1057 L117,1052 L122,1052 C122.552,1052 123,1051.55 123,1051 C123,1050.45 122.552,1050 122,1050 L122,1050 Z"
                    id="plus-square"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button (click)="collapseAll()">
          <svg width="1rem" height="1rem" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <title>minus-square</title>
              <desc>Created with Sketch Beta.</desc>
              <defs></defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-Set" transform="translate(-152.000000, -1035.000000)" fill="#707070">
                  <path
                    d="M174,1050 L162,1050 C161.448,1050 161,1050.45 161,1051 C161,1051.55 161.448,1052 162,1052 L174,1052 C174.552,1052 175,1051.55 175,1051 C175,1050.45 174.552,1050 174,1050 L174,1050 Z M182,1063 C182,1064.1 181.104,1065 180,1065 L156,1065 C154.896,1065 154,1064.1 154,1063 L154,1039 C154,1037.9 154.896,1037 156,1037 L180,1037 C181.104,1037 182,1037.9 182,1039 L182,1063 L182,1063 Z M180,1035 L156,1035 C153.791,1035 152,1036.79 152,1039 L152,1063 C152,1065.21 153.791,1067 156,1067 L180,1067 C182.209,1067 184,1065.21 184,1063 L184,1039 C184,1036.79 182.209,1035 180,1035 L180,1035 Z"
                    id="minus-square"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </th>
      <th class="text-left">
        <button (click)="isSourceErpFilterPopupDisplayed.set(!isSourceErpFilterPopupDisplayed())">
          <span class="text-[#808080] text-sm font-semibold pointer-events-none inset-y-0 right-0 inline-flex">
            ERP
            <svg
              class="fill-current h-4 w-4 text-gray-700 mt-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
            </svg>
          </span>
        </button>

        <!-- popup filter source ERP -->
        <div [formGroup]="filterSourceERPForm()" class="popup-filter pt-2" *ngIf="isSourceErpFilterPopupDisplayed()">
          <div
            formArrayName="checkboxesERP"
            class="w-[200px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex"
          >
            @for (erpCode of checkboxesErp.controls; track i; let i = $index) {
              <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                <input
                  type="checkbox"
                  [id]="i"
                  class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                  [formControlName]="i"
                  (change)="onSourceErpFilterChange(i)"
                  [value]="erpCode.value"
                />
                <label [for]="i" class="text-black text-l content__text checkbox__text">{{ getErpCode(i) }}</label>
              </div>
            }
          </div>
        </div>
      </th>
      <th class="text-left">
        <button>
          <span class="text-[#808080] text-sm font-semibold pointer-events-none inset-y-0 right-0 inline-flex">
            Component code
            <svg
              class="fill-current h-4 w-4 text-gray-700 mt-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
            </svg>
          </span>
        </button>
      </th>
      <th class="text-left">
        <button>
          <span class="text-[#808080] text-sm font-semibold pointer-events-none inset-y-0 right-0 inline-flex">
            Component name
            <svg
              class="fill-current h-4 w-4 text-gray-700 mt-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
            </svg>
          </span>
        </button>
      </th>
      <th class="text-left">
        <button (click)="isItemTypeFilterPopupDisplayed.set(!isItemTypeFilterPopupDisplayed())">
          <span class="text-[#808080] text-sm font-semibold pointer-events-none inset-y-0 right-0 inline-flex">
            Type
            <svg
              class="fill-current h-4 w-4 text-gray-700 mt-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
            </svg>
          </span>
        </button>

        <!-- popup filter item type -->
        <div [formGroup]="filterItemTypeForm()" class="popup-filter pt-2" *ngIf="isItemTypeFilterPopupDisplayed()">
          <div
            formArrayName="checkboxesItemType"
            class="w-[200px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex"
          >
            @for (itemType of checkboxesItemType.controls; track i; let i = $index) {
              <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                <input
                  type="checkbox"
                  [id]="i"
                  class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                  [formControlName]="i"
                  (change)="onItemTypeFilterChange(i)"
                  [value]="itemType.value"
                />
                <label [for]="i" class="text-black text-l content__text checkbox__text">{{ getItemType(i) }}</label>
              </div>
            }
          </div>
        </div>
      </th>
      <th class="text-left">
        <button (click)="isUnitFilterPopupDisplayed.set(!isUnitFilterPopupDisplayed())">
          <span class="text-[#808080] text-sm font-semibold pointer-events-none inset-y-0 right-0 inline-flex">
            Unit
            <svg
              class="fill-current h-4 w-4 text-gray-700 mt-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
            </svg>
          </span>
        </button>

        <!-- popup filter item unit -->
        <div [formGroup]="filterItemUnitForm()" class="popup-filter pt-2" *ngIf="isUnitFilterPopupDisplayed()">
          <div
            formArrayName="checkboxesItemUnit"
            class="w-[190px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex overflow-y-auto h-[300px]"
          >
            @for (itemUnit of checkboxesItemUnit.controls; track i; let i = $index) {
              <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                <input
                  type="checkbox"
                  [id]="i"
                  class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                  [formControlName]="i"
                  (change)="onItemUnitFilterChange(i)"
                  [value]="itemUnit.value"
                />
                <label [for]="i" class="text-black text-l content__text checkbox__text">{{ getItemUnit(i) }}</label>
              </div>
            }
          </div>
        </div>
      </th>
    </tr>
  </thead>

  <tbody>
    @for (item of itemsDisplay(); track indexEfaItem; let indexEfaItem = $index) {
      <tr class="bg-[#cad1e3] rounded-tl-xl rounded-tr-xl" [attr.data-testid]="'item-' + item.itemCode">
        <td>
          <div class="flex place-content-evenly">
            <button (click)="addItemSupply(indexEfaItem)">
              <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M4 12H20M12 4V20"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </button>

            <button (click)="displayRowLevel(indexEfaItem)">
              @if (displayRowLevelTwo()[indexEfaItem]) {
                <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              } @else {
                <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M9 6L15 12L9 18"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              }
            </button>
          </div>
        </td>
        <td class="text-left" [attr.data-testid]="'item-' + item.itemCode + '-sourceErpCode'">
          {{ item.sourceErpCode }}
        </td>
        <td class="text-left" [attr.data-testid]="'item-' + item.itemCode + '-userItemCode'">
          {{ item.userItemCode }}
        </td>
        <td class="text-left" [attr.data-testid]="'item-' + item.itemCode + '-itemName'">
          {{ item.itemName }}
        </td>
        <td class="text-left" [attr.data-testid]="'item-' + item.itemCode + '-itemType'">
          {{ item.itemType }}
        </td>
        <td class="text-left" [attr.data-testid]="'item-' + item.itemCode + '-itemUnitCode'">
          {{ item.itemUnitCode }}
        </td>
      </tr>
      @if (displayRowLevelTwo()[indexEfaItem]) {
        <tr [attr.data-testid]="'item-supplies-' + item.itemCode">
          <td colspan="6">
            <table class="w-full">
              <thead>
                <tr class="bg-[#d6f5e6] rounded-tl-xl rounded-tr-xl">
                  <th class="text-left">
                    <svg
                      width="1rem"
                      height="1rem"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#d6f5e6"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <g fill="#d6f5e6">
                          <path
                            d="M2.25 0A2.25 2.25 0 000 2.25v7.5A2.25 2.25 0 002.25 12h.25a.75.75 0 000-1.5h-.25a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v.25a.75.75 0 001.5 0v-.25A2.25 2.25 0 009.75 0h-7.5z"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            d="M6.25 4A2.25 2.25 0 004 6.25v7.5A2.25 2.25 0 006.25 16h7.5A2.25 2.25 0 0016 13.75v-7.5A2.25 2.25 0 0013.75 4h-7.5zM5.5 6.25a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-7.5z"
                            clip-rule="evenodd"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </th>
                  <th class="text-left w-28">
                    <button (click)="displayPopupScopeType(indexEfaItem)">
                      <span class="text-sm font-semibold inline-flex">
                        Scope type
                        <svg
                          class="fill-current h-4 w-4 text-gray-700 mt-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
                        </svg>
                      </span>
                    </button>

                    <!-- popup filter item supply scope type -->
                    <div
                      [formGroup]="filterItemSupplyScopeType()"
                      class="popup-filter pt-2"
                      *ngIf="isDisplayPopupScopeType(indexEfaItem)"
                    >
                      <div
                        formArrayName="checkboxesItemSupplyScopeType"
                        class="w-[190px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex h-[200px]"
                      >
                        @for (scopeType of checkboxesItemSupplyScopeType.controls; track i; let i = $index) {
                          <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                            <input
                              type="checkbox"
                              [id]="i"
                              class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                              [formControlName]="i"
                              [value]="scopeType.value"
                            />
                            <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                              getScopeType(i)
                            }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </th>
                  <th class="text-left w-32">
                    <button (click)="displayPopupScopeCode(indexEfaItem)">
                      <span class="text-sm font-semibold inline-flex">
                        Scope code
                        <svg
                          class="fill-current h-4 w-4 text-gray-700 mt-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
                        </svg>
                      </span>
                    </button>

                    <!-- popup filter item supply scope code -->
                    <div
                      [formGroup]="filterItemSupplyScopeCode()"
                      class="popup-filter pt-2"
                      *ngIf="isDisplayPopupScopeCode(indexEfaItem)"
                    >
                      <div
                        formArrayName="checkboxesItemSupplyScopeCode"
                        class="w-[190px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex overflow-y-auto h-[300px]"
                      >
                        @for (scopeCode of checkboxesItemSupplyScopeCode.controls; track i; let i = $index) {
                          <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                            <input
                              type="checkbox"
                              [id]="i"
                              class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                              [formControlName]="i"
                              [value]="scopeCode.value"
                            />
                            <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                              getScopeCode(i)
                            }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </th>
                  <th class="text-center w-16">
                    <button (click)="displayPopupStatus(indexEfaItem)">
                      <span class="text-sm font-semibold inline-flex">
                        Status
                        <svg
                          class="fill-current h-4 w-4 text-gray-700 mt-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
                        </svg>
                      </span>
                    </button>

                    <!-- popup filter item supply status -->
                    <div
                      [formGroup]="filterItemSupplyStatus()"
                      class="popup-filter pt-2"
                      *ngIf="isDisplayPopupStatus(indexEfaItem)"
                    >
                      <div
                        formArrayName="checkboxesItemSupplyStatus"
                        class="w-[190px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex h-[160px]"
                      >
                        @for (status of checkboxesItemSupplyStatus.controls; track i; let i = $index) {
                          <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                            <input
                              type="checkbox"
                              [id]="i"
                              class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                              [formControlName]="i"
                              [value]="status.value"
                            />
                            <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                              getItemSupplyStatus(i)
                            }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </th>
                  <th class="text-left w-40"><span class="text-sm font-semibold inline-flex">EFA Total</span></th>
                  <th class="text-left w-16"><span class="text-sm font-semibold inline-flex">Unit</span></th>
                  <th class="text-left">
                    <span class="text-sm font-semibold inline-flex">Processus label</span>
                  </th>
                  <th class="text-left w-48"><span class="text-sm font-semibold inline-flex">Data source</span></th>
                  <th class="text-left w-64">
                    <button (click)="displayPopupResponsible(indexEfaItem)">
                      <span class="text-sm font-semibold inline-flex">
                        Responsible
                        <svg
                          class="fill-current h-4 w-4 text-gray-700 mt-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M5.293 7.293L10 12l4.707-4.707-1.414-1.414L10 9.172l-3.293-3.293z" />
                        </svg>
                      </span>
                    </button>

                    <!-- popup filter item supply responsible -->
                    <div
                      [formGroup]="filterItemSupplyResponsible()"
                      class="popup-filter pt-2"
                      *ngIf="isDisplayPopupResponsible(indexEfaItem)"
                    >
                      <div
                        formArrayName="checkboxesItemSupplyResponsible"
                        class="w-[190px] py-2.5 bg-white rounded-xl shadow flex-col justify-start inline-flex overflow-y-auto h-[300px]"
                      >
                        @for (responsible of checkboxesItemSupplyResponsible.controls; track i; let i = $index) {
                          <div class="w-[200px] px-[30px] py-2.5 bg-white justify-start items-center gap-5 inline-flex">
                            <input
                              type="checkbox"
                              [id]="i"
                              class="w-[20px] h-[20px] relative bg-white rounded-md border border-black checkbox"
                              [formControlName]="i"
                              [value]="responsible.value"
                            />
                            <label [for]="i" class="text-black text-l content__text checkbox__text">{{
                              getItemSupplyResponsible(i)
                            }}</label>
                          </div>
                        }
                      </div>
                    </div>
                  </th>
                  <th class="text-left">
                    <svg
                      width="1rem"
                      height="1rem"
                      viewBox="0 -0.5 25 25"
                      fill="#d6f5e6"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                          fill="#d6f5e6"
                        ></path>
                      </g>
                    </svg>
                  </th>
                </tr>
              </thead>
              <tbody>
                @for (
                  itemSupply of itemSupplyIndex(indexEfaItem);
                  track indexItemSupply;
                  let indexItemSupply = $index
                ) {
                  @if (isItemSupplyNotFiltered(indexEfaItem, indexItemSupply)) {
                    <tr [attr.data-testid]="'item-supply-' + indexItemSupply + '-' + item.itemCode">
                      <td class="text-left">
                        <button (click)="duplicateItemSupply(indexEfaItem, itemSupply, indexItemSupply)">
                          <svg
                            width="1rem"
                            height="1rem"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <g fill="#000000">
                                <path
                                  d="M2.25 0A2.25 2.25 0 000 2.25v7.5A2.25 2.25 0 002.25 12h.25a.75.75 0 000-1.5h-.25a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v.25a.75.75 0 001.5 0v-.25A2.25 2.25 0 009.75 0h-7.5z"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M6.25 4A2.25 2.25 0 004 6.25v7.5A2.25 2.25 0 006.25 16h7.5A2.25 2.25 0 0016 13.75v-7.5A2.25 2.25 0 0013.75 4h-7.5zM5.5 6.25a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-7.5z"
                                  clip-rule="evenodd"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </button>
                      </td>
                      <td class="text-left">
                        <select
                          [formControl]="getFormControl('scopeType', indexEfaItem, indexItemSupply)"
                          [attr.data-testid]="'item-supply-' + indexItemSupply + '-' + item.itemCode + '-scope-type'"
                        >
                          <option [value]="null" disabled selected>Select a scope type</option>
                          @for (type of efaScopeType(); track j; let j = $index) {
                            <option
                              class="text-black font-normal font-['Work Sans']"
                              [value]="type"
                              [attr.data-testid]="'scope-type-' + type"
                            >
                              {{ type }}
                            </option>
                          }
                        </select>
                      </td>
                      <td class="text-left">
                        <select
                          [formControl]="getFormControl('scopeCode', indexEfaItem, indexItemSupply)"
                          [attr.data-testid]="'item-supply-' + indexItemSupply + '-' + item.itemCode + '-scope-code'"
                        >
                          @if (!getFormControlValue('scopeType', indexEfaItem, indexItemSupply)) {
                            <option [value]="null" disabled selected>Select a scope type before</option>
                          } @else {
                            @for (
                              scopeCode of efaScopesCode(
                                getFormControlValue('scopeType', indexEfaItem, indexItemSupply)
                              );
                              track i;
                              let i = $index
                            ) {
                              <option
                                class="text-black font-normal font-['Work Sans']"
                                [value]="scopeCode.scopeCode"
                                [attr.data-testid]="'scope-code-' + scopeCode.scopeCode"
                              >
                                {{ scopeCode.scopeCode }}
                              </option>
                            }
                          }
                        </select>
                      </td>
                      <td class="text-center">
                        @if (isItemSupplyStatusOK(indexEfaItem, indexItemSupply)) {
                          <button (click)="setStatusKo(indexEfaItem, indexItemSupply)">
                            <svg
                              width="2rem"
                              height="2rem"
                              fill="#029400"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#029400"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M 9 7 C 4.039063 7 0 11.035156 0 16 C 0 20.964844 4.039063 25 9 25 L 23 25 C 27.957031 25 32 20.957031 32 16 C 32 11.042969 27.957031 7 23 7 Z M 23 9 C 26.878906 9 30 12.121094 30 16 C 30 19.878906 26.878906 23 23 23 C 19.121094 23 16 19.878906 16 16 C 16 12.121094 19.121094 9 23 9 Z"
                                ></path>
                              </g>
                            </svg>
                          </button>
                        } @else {
                          @if (isItemSupplyValidable(indexEfaItem, indexItemSupply, itemSupply)) {
                            <button
                              (click)="setStatusOk(indexEfaItem, indexItemSupply, itemSupply)"
                              class="cursor-pointer visible"
                            >
                              <svg
                                width="2rem"
                                height="2rem"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#cccccc"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M8 15.5C6.21517 15.5 4.72218 14.137 4.53599 12.3681C4.51034 12.1245 4.51034 11.8755 4.53599 11.6319C4.72218 9.86302 6.21516 8.5 8 8.5C9.78483 8.5 11.2778 9.86302 11.464 11.6319C11.4896 11.8755 11.4896 12.1245 11.464 12.3681C11.2778 14.137 9.78483 15.5 8 15.5Z"
                                    fill="#cccccc"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.07434 18.75H15.9257C19.2472 18.75 22.075 16.3336 22.5931 13.0527C22.7032 12.3552 22.7032 11.6448 22.5931 10.9473C22.075 7.6664 19.2472 5.25 15.9257 5.25H8.07434C4.75284 5.25 1.92497 7.6664 1.40694 10.9473C1.29681 11.6448 1.29681 12.3552 1.40694 13.0527C1.92497 16.3336 4.75284 18.75 8.07434 18.75ZM8.07434 17.25C5.49095 17.25 3.2915 15.3706 2.88859 12.8188C2.80293 12.2763 2.80293 11.7237 2.88859 11.1812C3.2915 8.62942 5.49095 6.75 8.07434 6.75L15.9257 6.75C18.5091 6.75 20.7085 8.62942 21.1114 11.1812C21.1971 11.7237 21.1971 12.2763 21.1114 12.8188C20.7085 15.3706 18.5091 17.25 15.9257 17.25H8.07434Z"
                                    fill="#cccccc"
                                  ></path>
                                </g>
                              </svg>
                            </button>
                          } @else {
                            <button class="cursor-default">
                              <svg
                                width="2rem"
                                height="2rem"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#ffffff"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M8 15.5C6.21517 15.5 4.72218 14.137 4.53599 12.3681C4.51034 12.1245 4.51034 11.8755 4.53599 11.6319C4.72218 9.86302 6.21516 8.5 8 8.5C9.78483 8.5 11.2778 9.86302 11.464 11.6319C11.4896 11.8755 11.4896 12.1245 11.464 12.3681C11.2778 14.137 9.78483 15.5 8 15.5Z"
                                    fill="#ffffff"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.07434 18.75H15.9257C19.2472 18.75 22.075 16.3336 22.5931 13.0527C22.7032 12.3552 22.7032 11.6448 22.5931 10.9473C22.075 7.6664 19.2472 5.25 15.9257 5.25H8.07434C4.75284 5.25 1.92497 7.6664 1.40694 10.9473C1.29681 11.6448 1.29681 12.3552 1.40694 13.0527C1.92497 16.3336 4.75284 18.75 8.07434 18.75ZM8.07434 17.25C5.49095 17.25 3.2915 15.3706 2.88859 12.8188C2.80293 12.2763 2.80293 11.7237 2.88859 11.1812C3.2915 8.62942 5.49095 6.75 8.07434 6.75L15.9257 6.75C18.5091 6.75 20.7085 8.62942 21.1114 11.1812C21.1971 11.7237 21.1971 12.2763 21.1114 12.8188C20.7085 15.3706 18.5091 17.25 15.9257 17.25H8.07434Z"
                                    fill="#ffffff"
                                  ></path>
                                </g>
                              </svg>
                            </button>
                          }
                        }
                      </td>
                      <td class="text-left">
                        <input
                          type="number"
                          class="border rounded-lg border-y-gray-600 border-x-gray-600 w-full"
                          [step]="getIndicatorValueStep(indexEfaItem)"
                          [formControl]="getFormControl('indicatorValue', indexEfaItem, indexItemSupply)"
                          [attr.data-testid]="
                            'item-supply-' + indexItemSupply + '-' + item.itemCode + '-indicator-value'
                          "
                        />
                      </td>
                      <td class="text-left">eqCO2</td>
                      <td class="text-left">
                        <input
                          type="text"
                          class="border rounded-lg border-y-gray-600 border-x-gray-600"
                          [formControl]="getFormControl('processusLabel', indexEfaItem, indexItemSupply)"
                          [attr.data-testid]="
                            'item-supply-' + indexItemSupply + '-' + item.itemCode + '-processus-label'
                          "
                        />
                      </td>
                      <td class="text-left">
                        <input
                          type="text"
                          class="border rounded-lg border-y-gray-600 border-x-gray-600"
                          [formControl]="getFormControl('datasource', indexEfaItem, indexItemSupply)"
                          [attr.data-testid]="'item-supply-' + indexItemSupply + '-' + item.itemCode + '-datasource'"
                        />
                      </td>
                      <td class="text-left">
                        <app-responsible-list
                          (outputResponsibleSelected)="onResponsibleSelected(indexEfaItem, indexItemSupply, $event)"
                          [inputResponsibleSelected]="responsiblePreSelected(indexEfaItem, indexItemSupply)"
                          [inputDataTestidPrefix]="'item-supply-' + indexItemSupply + '-' + item.itemCode"
                        ></app-responsible-list>
                      </td>

                      <!--                    <td class="text-left">-->
                      <!--                      <input-->
                      <!--                        type="text"-->
                      <!--                        class="border rounded-lg border-y-gray-600 border-x-gray-600"-->
                      <!--                        [formControl]="getFormControl('updateDate', indexEfaItem, indexItemSupply)"-->
                      <!--                      />-->
                      <!--                    </td>-->

                      <!--                    <td class="text-left">-->
                      <!--                      <input-->
                      <!--                        type="text"-->
                      <!--                        class="border rounded-lg border-y-gray-600 border-x-gray-600"-->
                      <!--                        [formControl]="getFormControl('updateBy', indexEfaItem, indexItemSupply)"-->
                      <!--                      />-->
                      <!--                    </td>-->

                      <!--                    <td class="text-left">-->
                      <!--                      <input-->
                      <!--                        type="text"-->
                      <!--                        class="border rounded-lg border-y-gray-600 border-x-gray-600"-->
                      <!--                        [formControl]="getFormControl('comment', indexEfaItem, indexItemSupply)"-->
                      <!--                      />-->
                      <!--                    </td>-->

                      <td>
                        @if (isItemSupplyStatusKO(indexEfaItem, indexItemSupply)) {
                          <button (click)="deleteItemSupply(indexEfaItem, indexItemSupply)">
                            <svg
                              width="1rem"
                              height="1rem"
                              viewBox="0 -0.5 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                                  fill="#fa0000"
                                ></path>
                              </g>
                            </svg>
                          </button>
                        } @else {
                          <svg
                            width="1rem"
                            height="1rem"
                            viewBox="0 -0.5 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                                fill="#ffffff"
                              ></path>
                            </g>
                          </svg>
                        }
                      </td>
                    </tr>
                  }
                }
              </tbody>
            </table>
          </td>
        </tr>
      }
    }
  </tbody>
</table>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export default class SessionStorageService<T extends Record<string, any>> {
  storedData: T = {} as T;

  get(): T;
  get<K extends keyof T>(key: K): T[K];
  get<K extends keyof T>(key?: K): T | T[K] {
    if (key !== undefined) {
      return this.storedData[key];
    }
    return this.storedData;
  }

  store(newDataToStore: T) {
    this.storedData = newDataToStore;
  }
}

import { NgClass } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [NgClass],
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.css',
})
export class PopoverComponent {
  show: InputSignal<boolean> = input.required();
}

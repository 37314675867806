import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { SubsetStatus } from '../../../services/subset/model/subset.model';

@Component({
  selector: 'app-subset-status-text',
  standalone: true,
  imports: [NgClass],
  templateUrl: './subset-status-text.component.html',
  styleUrl: './subset-status-text.component.css',
})
export class SubsetStatusTextComponent {
  status = input.required<SubsetStatus | undefined>();
  statusColor: { [status in SubsetStatus]: string } = {
    [SubsetStatus.CREATED]: 'bg-slate-600 text-slate-50',
    [SubsetStatus.IN_PROGRESS]: 'bg-orange-400 text-orange-50',
    [SubsetStatus.PUBLISHED]: 'bg-green-600 text-green-50',
    [SubsetStatus.REWORK]: 'bg-zinc-600 text-zinc-50',
  };

  statusClass(status: SubsetStatus | undefined): string {
    return status ? this.statusColor[status] : '';
  }
}

{
  "name": "cactus-frontend",
  "version": "0.3.0",
  "scripts": {
    "ng": "ng",
    "start": "bash src/scripts/aws-ssm-environments.sh && ng serve",
    "build": "echo \"launching build for environment ${ENVIRONMENT}\" && ng build --configuration ${ENVIRONMENT}",
    "watch": "ng build --watch --configuration development-local",
    "test": "ng test --no-watch --no-progress --browsers=ChromeHeadless",
    "lint": "ng lint",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@aws-amplify/ui-angular": "^5.0.25",
    "aws-amplify": "^6.6.2",
    "lodash.reduce": "^4.6.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.7.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@types/jasmine": "~5.1.4",
    "@types/jest": "^29.5.13",
    "@types/lodash.reduce": "^4.6.9",
    "angular-eslint": "^18.3.1",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.11.1",
    "eslint-plugin-prettier": "^5.2.1",
    "globals": "^15.9.0",
    "husky": "^9.1.6",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.47",
    "prettier": "^3.3.3",
    "tailwindcss": "^3.4.13",
    "typescript": "^5.5.4",
    "typescript-eslint": "^8.7.0"
  }
}

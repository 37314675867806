<div class="dropdown-container h-12" #dropdownContainer>
  <input type="text" class="min-h-full" placeholder="Search a plant" [formControl]="searchTerm" />

  @if (!isCustomDropdownOpen()) {
    <select [formControl]="scopeToAdd" class="min-h-full" data-testid="plant-code-list__select">
      <option [value]="null" disabled selected>Select a plant</option>
      @for (scope of getScopeAvailableList(); track $index) {
        <option class="text-black font-normal font-['Work Sans']" [value]="scope.plantCode">
          {{ scope.plantCode }} {{ scope.name }}
        </option>
      }
    </select>
  } @else {
    <select (click)="openManualSelect()"></select>
    <div class="dropdown-list">
      @for (scope of getScopeAvailableList(); track $index) {
        <button class="dropdown-item text-black font-normal font-['Work Sans']" (click)="selectScope(scope)">
          {{ scope.plantCode }} {{ scope.name }}
        </button>
      }
    </div>
  }
</div>
@if (displayList()) {
  @for (scope of scopesSelected(); track $index) {
    <app-plant-code [scope]="scope"></app-plant-code>
  }
}

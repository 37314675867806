<div class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
  <div class="bg-white rounded-lg shadow-lg p-6 w-1/3">
    <h2 [ngClass]="dangerousAction() ? 'text-red-600' : 'text-emerald-600'" class="text-xl font-semibold mb-4">
      {{ title() }}
    </h2>
    <p class="mb-6">{{ content() }}</p>

    <div class="flex justify-end space-x-4">
      @if (cancelButton()) {
        <button
          class="text-slate-800 border-slate-800 border-2 hover:bg-slate-50 px-4 py-2 rounded"
          (click)="onCancel()"
          [disabled]="disableButtons()"
        >
          Cancel
        </button>
      }
      @if (confirmButton()) {
        <button
          [ngClass]="{
            'bg-red-600': dangerousAction(),
            'bg-slate-800': !dangerousAction(),
          }"
          class="text-white px-4 py-2 rounded"
          (click)="onConfirm()"
          [disabled]="disableButtons()"
        >
          Confirm
        </button>
      }
      @if (closeButton()) {
        <button class="bg-slate-800 text-white px-4 py-2 rounded" (click)="onClose()" [disabled]="disableButtons()">
          Close
        </button>
      }
    </div>
  </div>
</div>

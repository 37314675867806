<!--  header-->
<nav
  class="inline-flex flex-nowrap w-full items-center bg-white text-emerald-600 h-20 px-10 shadow-slate-100 shadow-lg"
>
  <img ngSrc="../assets/ARKEMA_Logo_RVB.jpg" alt="Arkema Logo" (load)="onLogoLoad()" height="40" width="130" priority />

  @if (isLoaded()) {
    <div class="inline-flex justify-between items-center w-full pl-10">
      <div class="inline-flex gap-6">
        <span
          routerLinkActive="underline"
          class="hover:underline underline-offset-8 decoration-2"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="'/'" (click)="dispatchChangePageEvent('/')">Subsets</a>
          <a [routerLink]="'/new-subset'" class="hidden"></a>
        </span>
        <a
          [routerLink]="'efa'"
          routerLinkActive="underline"
          (click)="dispatchChangePageEvent('/efa')"
          class="hover:underline underline-offset-8 decoration-2"
          >EFA Management</a
        >
      </div>
      <button (click)="showVersion.set(!showVersion())">
        <div class="inline-flex items-center gap-4">
          <div
            class="text-xs font-medium py-1 px-3 rounded-lg border-2"
            [ngClass]="
              isProfileAdvanced() ? 'text-emerald-600 border-emerald-600' : 'text-indigo-600 border-indigo-600'
            "
          >
            {{ isProfileAdvanced() ? 'Advanced' : 'Standard' }}
          </div>
          <div
            class="flex items-center justify-center w-10 h-10 rounded-full relative"
            [ngClass]="isProfileAdvanced() ? 'bg-emerald-600' : 'bg-indigo-600'"
          >
            <div class="text-white text-sm font-semibol">{{ getShortName() }}</div>
          </div>

          <div class="relative z-50 -left-60 top-12">
            <app-popover [show]="showVersion()">
              <div class="text-left text-sm font-semibold w-[200px]">
                <span *ngFor="let version of cactusVersions | async | slice: 0"> {{ version }}<br /> </span>
              </div>
            </app-popover>
          </div>
        </div>
      </button>
    </div>
  }
</nav>

<!--  body-->
@if (isLoaded()) {
  <main class="p-10">
    <router-outlet></router-outlet>
  </main>
} @else {
  <h2>Connecting to cactus...</h2>
}

<div class="justify-end items-center gap-5 inline-flex w-full">
  <button
    (click)="cancelCreate()"
    [disabled]="sending()"
    class="border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-lg px-4 py-2"
  >
    Cancel
  </button>
  <button
    (click)="applySubset()"
    [disabled]="sending()"
    class="border-2 bg-slate-600 hover:bg-slate-500 text-white rounded-lg px-4 py-2"
  >
    @if (editMode()) {
      Save & Quit
    } @else {
      Create
    }
  </button>
</div>
<div class="flex gap-24">
  <div class="flex flex-col w-1/2 gap-6 xl:pr-48">
    <label [ngClass]="hasError('comment') ? 'border-red-500' : ''"
      >Subset Name
      <input
        type="text"
        placeholder="Enter a name for your subset"
        [ngClass]="hasError('name') ? 'border-red-500' : ''"
        [formControl]="subsetName"
      />
    </label>

    <div class="inline-flex gap-20">
      <label [ngClass]="hasError('responsible') ? 'border-red-500' : ''"
        >Responsible
        <select [formControl]="responsibleSelected">
          <option value="" disabled selected>Select a responsible</option>
          @for (responsible of responsibleList(); track i; let i = $index) {
            <option>{{ responsible.firstName }} {{ responsible.lastName }}</option>
          }
        </select>
      </label>

      <label for="fiscal-year"
        >Year
        @if (editMode()) {
          <select id="fiscal-year" [formControl]="yearSelected">
            <option value="" disabled selected>Select a year</option>
            @for (year of yearList(); track i; let i = $index) {
              <option>
                {{ year }}
              </option>
            }
          </select>
        } @else {
          <div class="pt-3.5">{{ yearSelected.value }}</div>
        }
      </label>
    </div>
    <label [ngClass]="hasError('comment') ? 'border-red-500' : ''">
      Comment
      <input type="text" placeholder="Add commentary to your subset" [formControl]="subsetComment" />
    </label>
  </div>
  <div class="w-1/2 xl:pr-48">
    <label for="subset-scope" [ngClass]="hasError('scope') ? 'border-red-500' : ''"
      >Subset scope
      <input
        id="subset-scope"
        type="text"
        placeholder="Search for a plant"
        list="id-subset-scope"
        (keyup.enter)="onScopeToAdd()"
        [(ngModel)]="scopeToAdd"
        [disabled]="sending()"
      />
      <datalist id="id-subset-scope">
        <option [value]="''" disabled selected>Select a plant</option>
        @for (scope of scopeAvailableList(); track i; let i = $index) {
          <option class="text-slate-800" [value]="scope.plantCode">{{ scope.plantCode }} {{ scope.name }}</option>
        }
      </datalist>
      <div
        class="grid grid-cols-[auto_1fr_auto] [&>div]:flex [&>div]:justify-left [&>div]:items-center [&>div]:p-3 [&>div]:text-slate-800 fancy-grid"
      >
        @for (scope of scopeSelected(); track i; let i = $index) {
          @if (scope && scope.plantCode) {
            <div>{{ scope.plantCode }}</div>
            <div class="text-sm">{{ scope.name }}</div>
            <div class="flex justify-center items-center">
              <button
                class="w-6 h-6 bg-slate-600 rounded-[20px] flex items justify-center"
                (click)="removeScope(scope.plantCode)"
              >
                <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                  <line x1="1" y1="1" x2="9" y2="9" stroke="white" stroke-width="2" />
                  <line x1="1" y1="9" x2="9" y2="1" stroke="white" stroke-width="2" />
                </svg>
              </button>
            </div>
          }
        }
      </div>
    </label>
  </div>
</div>

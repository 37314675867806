import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import { CactusService } from '../cactus.service';
import { environment } from '../../../environments/environment';
import * as packageJson from '../../../../package.json';

interface CactusVersion {
  'cactus-engine': string;
  'cactus-backend': string;
}

@Injectable({
  providedIn: 'root',
})
export class CactusVersionService extends CactusService<CactusVersion> {
  retrieveVersions(): Observable<string[]> {
    return this.http.get<{ data: CactusVersion }>(`${environment.api}retrieveVersion`).pipe(
      map(({ data }: { data: CactusVersion }) => {
        this.inMemoryData = [data];

        return [
          `Cactus-engine: ${data['cactus-engine']}`,
          `Cactus-backend: ${data['cactus-backend']}`,
          `Cactus-frontend: ${packageJson.version}`,
        ];
      }),
      catchError(this.handleHttpError),
    );
  }
}
